export const common = {
    //AppTopbarHomePage
    "digital-sign-check": "Kiểm tra chữ ký số",
    "plugin-down": "Tải xuống plugin",
    "search-doc": "Tra cứu tài liệu",
    "app": "Ứng dụng",
    "common.create_ticket_support": "Gửi yêu cầu hỗ trợ",

    //CategoryContractType
    "list-type-contract": "Loại hợp đồng",
    "code-name-contracttype": "Mã, tên loại hợp đồng",
    "code-contracttype": "Mã loại hợp đồng",
    "name-contracttype": "Tên loại hợp đồng",
    "procedure-sign": "Quy trình ký",
    "ans-yes": "Có",
    "ans-no": "Không",
    "description": "Mô tả",
    "create-time": "Ngày tạo",
    "add-contracttype": "Thêm mới loại hợp đồng",
    "view-contracttype": "Xem chi tiết",
    "update-contracttype": "Cập nhật loại hợp đồng",
    "typename-contracttype": "Nhập tên loại hợp đồng",
    "type-description": "Nhập mô tả",
    "cancel": "Hủy",
    "update": "Cập nhật",
    "save": "Lưu",
    "del-contracttype": "Xóa loại hợp đồng",
    "del-contracttype-confirm": "Bạn có chắc chắn muốn xóa loại hợp đồng",
    "del-contracttype-multiconfirm": "Bạn có chắc chắn muốn xóa danh sách loại hợp đồng đã chọn không?",
    "contract_type_create": "Thêm mới loại hợp đồng",
    "input-contracttype-name": "Nhập tên loại hợp đồng",
    "categorize": "Chọn loại quy trình",
    "type-select": "Chọn loại quy trình",
    "sign-by-procedure": "Tổ chức của tôi ký theo quy trình",
    "step-name": "Tên bước",
    "step-name-empty": "Tên bước không được bỏ trống!",
    "type-confirm-empty": "Loại xác nhận không được bỏ trống!",
    "time-sign": "Thời hạn ký (ngày)",
    "one-day": "1 ngày",
    "five-day": "5 ngày",
    "nine-day": "9 ngày",
    "unlimited": "Không giới hạn",
    "time-approve-empty": "Thời gian ký không được bỏ trống!",
    "submit-type": "Loại xác nhận",
    "submit-only": "Chỉ duyệt",
    "submit-sign": "Duyệt và ký",
    "approve-account": "Tài khoản xác nhận",
    "select-approve-account": "Chọn",
    "approve-account-empty": "Tài khoản xác nhận không được bỏ trống!",
    "TypeContract.type_confirm_invalid": "Loại xác nhận không hợp lệ!",
    "can-change-signer": "Có thể thay đổi người ký/duyệt",
    "approver": "Người phê duyệt",
    "select-approver": "Chọn người phê duyệt",
    "approver-empty": "Người phê duyệt không được bỏ trống!",
    "approve-group": "Nhóm phê duyệt",
    "select-approve-group": "Chọn nhóm phê duyệt",
    "approve-group-empty": "Nhóm phê duyệt không được bỏ trống!",
    "number-approved": "Số người phê duyệt",
    "some-people": "Một số người",
    "quantity": "Số lượng",
    "type-quantity": "Nhập số lượng người phê duyệt",
    "quantity-empty": "Số lượng người phê duyệt không được bỏ trống và phải lớn hơn 0!",
    "add-step": "Thêm bước",
    "list-step-procedure": "Danh sách các bước trong quy trình ký",
    "step-in-procedure-sign": "Các bước trong quy trình ký",
    "step": "Bước",
    "sure-delete-canvas": "Bạn có chắc chắn muốn xóa hết canvas?",

    //CategoryDocument
    "manage-docsample": "Quản lý mẫu tài liệu",
    "code-name-docsample": "Tên, mã mẫu tài liệu",
    "select-doctype": "Chọn loại tài liệu",
    "code-docsample": "Mã mẫu tài liệu",
    "name-docsample": "Tên mẫu tài liệu",
    "del-docsample-multiconfirm": "Bạn có chắc chắn muốn xóa danh sách mẫu tài liệu đã chọn không?",
    "del-docsample-confirm": "Bạn có chắc chắn muốn xóa mẫu tài liệu",
    "add-supplier-sample": "Thêm mới theo mẫu của CyberLotus",
    "doc-info": "Thông tin tài liệu",
    "select-docsample": "Chọn mẫu tài liệu",
    "del-docsample": "Xóa mẫu tài liệu",
    "info-docsample": "Thông tin mẫu tài liệu",
    "next": "Tiếp theo",
    "add-newsample-enterprise": "Thêm mới mẫu của doanh nghiệp",
    "cate-document-view-document": "Xem mẫu tài liệu",
    "doc-type": "Loại tài liệu",

    //CategoryDocumentType
    "list-type-doc": "Danh mục loại tài liệu",
    "code-name-doctype": "Mã, tên loại tài liệu",
    "code-doctype": "Mã loại tài liệu",
    "name-doctype": "Tên loại tài liệu",
    "add-doctype": "Thêm mới loại tài liệu",
    "update-doctype": "Cập nhật loại tài liệu",
    "typename-doctype": "Nhập tên loại tài liệu",
    "del-doctype": "Xóa loại tài liệu",
    "del-doctype-confirm": "Bạn có chắc chắn muốn xóa loại tài liệu",
    "del-doctype-multiconfirm": "Bạn có chắc chắn muốn xóa danh sách loại tài liệu đã chọn không?",
    "del-process-type": "Bạn có chắc muốn xóa loại quy trình",
    "del-process-type-list": "Bạn có chắc chắn muốn xóa danh sách loại quy trình đã chọn không",

    //CategoryPosition
    "title-name": "Tên chức danh",

    //CategoryProcedure
    "name-code-procedure": "Tên hoặc mã quy trình",
    "code-procedure": "Mã quy trình",
    "name-procedure": "Tên quy trình",
    "object-approve": "Đối tượng phê duyệt",
    "select-proceduretype": "Chọn loại quy trình",
    "permission": "Cấp quyền",

    //CategoryProcedureAdd
    "process_create": "Tạo mới quy trình",
    "input-procedure-name": "Nhập tên quy trình",
    "page-numbering": "Đánh số văn bản",
    "time-approve": "Thời gian phê duyệt",
    "approve-type": "Loại hình phê duyệt",
    "select-approve-type": "Chọn loại hình phê duyệt",
    "approve-type-empty": "Loại hình phê duyệt không được bỏ trống.",

    //categorysubmission
    "submission": "Trình ký",
    "sign-manage": "Trình ký văn bản",
    "search-by-title": "Tìm theo tiêu đề trình ký",
    "select-procedure": "Chọn quy trình",
    "select-status": "Chọn trạng thái",
    "numbering-status": "Phân loại",
    "from": "Từ ngày",
    "search-from": "Tìm từ ngày tạo",
    "to": "đến",
    "to-date": "Đến ngày",
    "title-submit": "Tiêu đề trình ký",
    "procedure-name": "Tên quy trình",
    "date-start": "Ngày bắt đầu",
    "end-date": "Ngày kết thúc",
    "date-complete": "Ngày hoàn thành",
    "task": "Tác vụ",
    "show-from": "Hiển thị từ",
    "sum": "Tổng",
    "record": "bản ghi",
    "draft": "Lưu nháp",
    "not-your-turn": "Chưa đến lượt",
    "waiting-turn": "Chờ đến lượt",
    "waiting-sign": "Chờ ký",
    "waiting-approve": "Chờ duyệt",
    "pause": "Tạm dừng",
    "refuse-sign": "Từ chối ký",
    "refuse-review": "Từ chối duyệt",
    "cancelled": "Đã bị hủy",
    "complete": "Hoàn thành",
    "click-detail": "Click để xem chi tiết",
    "edit": "Chỉnh sửa",
    "number": "Đánh số",
    "submit": "Trình ký",
    "batch_signer": "Trình ký theo lô",
    "add-quick-signer": "Thêm mới trình ký nhanh",
    "update-quick-signer": "Cập nhật trình ký nhanh",
    "isnumbering": "Có đánh số",
    "nonumbering": "Không đánh số",
    "numbered": "Đã đánh số",
    "type-submission": "Loại trình ký",
    "submitbyprocedure": "Trình ký theo quy trình",
    "quicksubmit": "Trình ký nhanh",
    "signing_process": "Trình ký theo quy trình",
    "resend-doc": "Gửi lại văn bản",
    "send-doc": "Gửi tài liệu",
    "export-excel": "Xuất Excel",
    "export-data": "Xuất dữ liệu",
    "export": "Xuất",
    "type-file": "Loại file",
    //CategorySubmissionAdd
    "submission_creat_new": "Tạo mới trình ký văn bản",
    // "batch_signer_creat_new": "Tạo mới trình ký theo lô",
    "type-title": "Nhập tiêu đề",
    "type-title-submit": "Nhập tiêu đề trình ký",
    "procedure-sample": "Mẫu quy trình",
    "select-procedure-sample": "Chọn mẫu quy trình",
    "doc-submit": "Văn bản trình ký",
    "select-submit-doc": "Chọn văn bản trình ký",
    "select-doc": "Chọn tài liệu",
    "select-attach-doc": "Chọn tài liệu đính kèm",
    "sent-doc": "Tài liệu gửi đi",
    "attach-doc": "Tài liệu đính kèm",
    "list-doc": "Danh sách tài liệu",
    "list-doc-attach": "Danh sách tài liệu đính kèm",
    "attach-doc-noca": "Tài liệu đính kèm (không ký số)",
    "action-after-submit-title": "Hành động sau khi hoàn tất",
    "action-after-submit": "Gửi bản sao cho bên không tham gia luồng ký",
    "send-email": "Gửi email:",
    "select-user": "Chọn người dùng",
    "send-email-outside": "Gửi email ngoài hệ thống:",
    "type-address-enter": "Nhập địa chỉ email và bấm enter để thêm",
    "set-position-ca": "Đặt vị trí CKS",
    "create-submission": "Tạo trình ký",
    "set-signature-position": "Đặt vị trí chữ ký",
    "action-select-user": "Người dùng doanh nghiệp",
    "set-external-partners": "Đối tác bên ngoài",

    //CategoryQuickSubmissionAdd
    "email-is-not-formatted-correctly": "Email không đúng định dạng",
    "document-does-not-need-to-be-positioned": "Văn bản không cần đặt vị trí",
    "not-need-locate-doc": "Tài liệu không cần đặt vị trí",
    "create-new-quick-signer": "Tạo mới trình ký nhanh",
    "not-blank-participant": "Bên tham gia không được bỏ trống",
    "not-blank-time-type": "Loại thời gian không được bỏ trống",
    "choose-manager": "Chọn người quản lý",
    "not-blank-manager": "Người quản lý không được bỏ trống",
    "enter-partner-name": "Nhập tên đối tác",
    "enter-email": "Nhập email",
    "not-blank-email": "Email không được bỏ trống",

    //CategorySubmissionConfirm
    "list-approval": "Danh sách phê duyệt",
    "refuse": "Từ chối",

    //CategorySubmissionConfirmed
    "list-doc-approved": "Danh sách văn bản đã phê duyệt",
    "title-doc": "Tiêu đề văn bản",

    //CategoryRefuse
    "list-refused-doc": "Danh sách văn bản đã từ chối, quá hạn",

    //CategorySubmissionView
    "submission_info": "Thông tin trình ký",
    "quick_submission_info": "Thông tin trình ký nhanh",
    "code": "Mã",
    "procedure": "Quy trình",
    "procedure-numbering": "Quy trình này có đánh số văn bản",
    "confirm-BCT": "Xác nhận hợp đồng điện tử của Bộ Công Thương",
    "has-check": "Có kiểm tra",
    "no-check": "Không kiểm tra",
    "mail-finish": "Mail gửi khi hoàn tất trình ký",
    "list-doc-submission": "Danh sách văn bản trình ký",
    "set-position": "Đặt vị trí ký",
    "down-all-file": "Tải xuống tất cả file",
    "down-all-doc": "Tải xuống tất cả văn bản",
    "list-attach-doc": "Danh sách tài liệu đính kèm",
    "step-info": "Thông tin các bước",
    "participants-list": "Danh sách các bên tham gia",
    "information-title": "Thông tin",
    "note-info": "Ghi chú",
    "signer-title": "Tiêu đề trình ký",
    "confirmed-status": "Trạng thái",
    "create-date": "Ngày tạo",
    "update-date": "Ngày cập nhật",
    "start-date": "Ngày bắt đầu",
    "complete-date": "Ngày hoàn thành",
    "download-all-doc": "Tải xuống tất cả văn bản",
    "back-btn": "Trở lại",

    // ListFilePreview
    "see-doc": "See",
    "check-CKS": "Kiểm tra CKS",
    "download-doc": "Tải xuống",
    "view-sign-location": "Xem vị trí ký",
    "view-location": "Xem vị trí",
    "view-file-detail": "Xem chi tiết file",
    "view-file-signing-location-details": "Xem chi tiết vị trí ký file",

    // ViewProcedureDetail
    "produre-detail_internal": "Nội bộ",
    "produre-detail_external-partners": "Đối tác ngoài hệ thống",
    "signer/approver": "Người ký/duyệt",
    "group_signer/approver": "Nhóm ký/duyệt",
    "signing/approval-date": "Ngày ký/duyệt",
    "have-sent-one-message": "Có gửi lời nhắn",
    "produre-detail_participating-parties": "Bên tham gia",
    "produre-detail_step-name": "Tên bước",
    "produre-detail_approval-time": "Thời gian phê duyệt",
    "confirm-type": "Loại xác nhận",
    "only-browse": "Chỉ duyệt",
    "browse-and-sign": "Duyệt và ký",
    "common.approve-only": "Chỉ duyệt",
    "common.approve-sign": "Duyệt và ký",
    "common.sign-only": "Chỉ ký",
    "type of approval": "Loại hình phê duyệt",
    "produre-detail_user": "Người dùng",
    "produre-detail_title": "Chức danh",
    "produre-detail_approver": "Người phê duyệt",
    "produre-detail_approval-group": "Nhóm phê duyệt",
    "number-of-people-registered": "Số người đăng ký",
    "produre-detail_all": "Tất cả",
    "produre-detail_some-people": "Một số người",
    "produre-detail_quantity": "Số lượng",
    "produre-detail_manager": "Người quản lý",
    "produre-detail_description": "Mô tả",
    "reason-for-refusal": "Lý do từ chối",
    "produre-detail_message": "Lời nhắn",

    //CategoryTypeProcedure
    "no-record": "Không có bản ghi nào!",
    "code-type-procedure": "Mã loại quy trình",
    "name-type-procedure": "Tên loại quy trình",
    "type-namecode-proceduretype": "Nhập tên hoặc mã loại quy trình",
    "add-proceduretype": "Thêm mới loại quy trình",
    "update-proceduretype": "Cập nhật loại quy trình",
    "type-name-procedure": "Nhập tên loại quy trình",

    // DialogSendDoc
    "document-title": "Tiêu đề tài liệu",
    "choose-partner": "Chọn đối tác",
    "enter-message": "Nhập lời nhắn",

    //ContractManagement
    "contract-management": "Trình ký hợp đồng",
    "search-contract-by-title": "Tên, mã hoặc số hợp đồng",
    "check-bct": "Kiểm tra bộ công thương",
    "title-contract": "Tiêu đề hợp đồng",
    "number-contract": "Số hợp đồng",
    "type-number-contract": "Nhập số hợp đồng",
    "search-from-effect": "Tìm từ ngày hiệu lực",
    "type-title-contract": "Nhập tiêu đề hợp đồng",
    "out-of-date": "Quá hạn",
    "pending_create_file": "Đang chờ xử lý",
    "expiring": "Đang hiệu lực",
    "expire": "Hết hiệu lực",
    "effect": "Hiệu lực",
    "contract_create_new": "Tạo mới hợp đồng",
    "type-contract": "Loại hợp đồng",
    "select-type-contract": "Chọn loại hợp đồng",
    "select-contract-doc": "Chọn hợp đồng trình ký",
    "doc-contract": "Hợp đồng trình ký",
    "doc-contract-ca": "Hợp đồng trình ký (sẽ ký số)",
    "list-contract": "Danh sách hợp đồng",
    "list-contract-sub": "Danh sách hợp đồng trình ký",
    "code-contract": "Mã hợp đồng",
    "continue": "Tiếp tục",
    "validity-of-contract": "Hiệu lực hợp đồng",
    "contract_update": "Cập nhật hợp đồng",
    "add-participants": "Thêm bên tham gia",
    "save-and-exit": "Lưu và thoát",
    "come-back": "Quay lại",
    "send-contract": "Gửi hợp đồng",
    "resend-contract": "Gửi lại hợp đồng",
    "save-position": "Lưu vị trí",
    "contract-management-confirm": "Phê duyệt hợp đồng",
    "participating-parties": "Các bên tham gia",
    "create-contract": "Tạo hợp đồng",
    "Contract.change_type_contract": "Loại hợp đồng đã thay đổi, bạn có muốn cập nhật không?",
    "Contract.detail_type_contract": "Chi tiết loại hợp đồng",
    "Contract.contract_has_change": "Loại hợp đồng có thay đổi",
    "Contract.contract-management-confirmed.title": "Hợp đồng đã phê duyệt",
    "Contract.contract-management-refuse.title": "Hợp đồng đã từ chối, quá hạn",

    // ContractManagementAdd
    "line-before": "Hợp đồng có thời gian trong",
    "line-1-after-case-1-month": "tháng và có hiệu lực bắt đầu từ ngày hoàn thành ký",
    "line-1-after-case-many-month": "tháng và có hiệu lực bắt đầu từ ngày hoàn thành ký",
    "line-2-after-case-1-month": "tháng và có hiệu lực bắt đầu từ ngày",
    "line-2-after-case-many-month": "tháng và có hiệu lực bắt đầu từ ngày",
    "line-3": "Hợp đồng có hiệu lực bắt đầu từ ngày",
    "Contract.label_expire_time": "và hiệu lực hợp đồng đến ngày",

    // ContractManagementDelete
    "delete-contract-header": "Xóa hợp đồng",
    "delete-contract-before": "Bạn có chắc chắn muốn xóa hợp đồng",
    "delete-contract-after": "không",
    "delete-list-contract": "Bạn có chắc chắn muốn xóa danh sách hợp đồng đã chọn không",

    // ContractManagementView
    "contract-info": "Thông tin hợp đồng",
    "contract-type": "Loại",
    "valid-contract": "Hợp đồng có hiệu lực",
    "month-from-contract-1": "tháng bắt đầu từ",
    "month-from-contract-many": "tháng bắt đầu từ",
    "month-to-contract": "đến ngày",
    "send-copy-contract": "Sau khi hoàn thành gửi bản sao cho các bên tham gia",
    "send-type": "Loại gửi",
    "start-from": "bắt đầu từ",

    // ViewTypeContractDetail
    "internal-account": "Tài khoản nội bộ",
    "organizational-partner": "Đối tác tổ chức",
    "personal-partner": "Đối tác cá nhân",
    "my-organization": "Tổ chức của tôi",
    "choose-party-to-join": "Chọn bên tham gia",
    "signing-deadline": "Thời hạn ký",
    "choose-signing-deadline": "Chọn thời hạn ký",
    "participating-party": "Bên tham gia",
    "representative": "Người đại diện",
    "organization-name": "Tên tổ chức (nếu có)",

    // ContactManagementResend
    "sure-to-resend-before": "Bạn có chắc muốn gửi lại hợp đồng",
    "sure-to-resend-after": "không",

    //CheckSign
    "box-click": "Kéo thả file vào box này",
    "for-check": "Để xác thực văn bản đã ký số",
    "for-sign": "Để bắt đầu ký số",
    "or": "hoặc",
    "file-select": "Chọn file tải lên",

    //CheckSignDigital
    "upload-guide": "(Hỗ trợ file có định dạng .pdf, .ppt, .pptx, .doc, .docx, .xls, .xlsx)",
    "upload-guide-sign": "(Hỗ trợ tải lên định dạng .pdf)",
    "doc-list": "Danh sách tài liệu",
    "doc-list-permissions": "Danh sách file ủy quyền",
    "doc-submit-ca": "Văn bản trình ký (sẽ ký số)",
    "location-sign-not-exist": "Không tồn tại vị trí ký!",

    // ViewPdfPositionSignContract
    "list-step": "Danh sách bước",
    "sign-location": "Vị trí ký",

    //Common
    "title-c": "Chức danh",
    "title": "Tiêu đề",
    "digital-sign": "Ký số",
    "cts": "Chữ ký số",
    "signature": "Chữ ký",
    "data": "Dữ liệu",
    "login": "Đăng nhập",
    "account-manage": "Quản lý tài khoản",
    "logout": "Đăng xuất",
    "note": "Ghi chú",
    "type-note": "Nhập ghi chú",
    "user": "Người dùng",
    "user-list": "Danh sách người dùng",
    "user-empty": "Người dùng không được bỏ trống.",
    "position": "Chức danh",
    "position-empty": "Chức danh không được bỏ trống.",
    "empty": "Không có",
    "all": "Tất cả",
    "not-blank": "không được bỏ trống",
    "account": "Tài khoản",
    "external-account": "Tài khoản bên ngoài",
    "manager": "Người quản lý",
    "receiver": "Người nhận",
    "name": "Tên",
    "type-name": "Nhập tên",
    "role": "Vai trò",
    "upgrade": "Nâng cấp",
    "skip-upgrade": "Bỏ qua nâng cấp",
    "person": "Cá nhân",
    "no": "STT",
    "enter": "Nhập",
    "time": "Thời gian",
    "executor": "Người thực hiện",
    "search": "Tìm kiếm",
    "add": "Thêm mới",
    "create": "Tạo mới",
    "retype": "Nhập lại",
    "sort": "Sắp xếp",
    "convert": "Chuyển đổi",
    "delete": "Xóa",
    "remove": "Xóa",
    "copy": "Sao chép",
    "hide": "Ẩn",
    "copy-success": "Sao chép thành công",
    "select": "Lựa chọn",
    "view": "Xem",
    "home": "Trang chủ",
    "created_by": "Người tạo",
    "created_at": "Thời gian tạo",
    "updated_by": "Người cập nhật",
    "updated_at": "Thời gian cập nhật",
    "action": "Tác vụ",
    "status": "Trạng thái",
    "close": "Hủy",
    "want": "muốn",
    "download": "Tải xuống",
    "contract": "Hợp đồng",
    "code-document": "Mã văn bản",
    "support": "Hỗ trợ",
    "import-excel": "Nhập Excel",
    "download-sample-excel": "Tải file mẫu",
    "select-excel-file": "Lựa chọn file tài liệu",
    "confirm": "Xác nhận",
    "lock-reason": "Lý do khóa",
    "no-result": "Không tìm được kêt quả",
    "content": "Nội dung",
    "sign-for-approval": "Ký duyệt",
    "closepopup": "Đóng",
    "hour": "Giờ",
    "date": "Ngày",
    "send": "Gửi",
    "day": "Ngày",
    "message": "Lời nhắn",
    "time_at": "vào lúc",
    "page": "Trang",
    "browse": "Ký duyệt",
    "accept": "Đồng ý",
    "retry": "Thử lại",
    "approve": "Phê duyệt",
    "existed": "Đã tồn tại",
    "choose": "Chọn",
    "preview": "Xem trước",
    "create-doc": "Tạo tài liệu",
    "partner": "Đối tác",
    "general-doc": "Tài liệu chung",
    "delete-all": "Xóa toàn bộ",
    "new": "Mới",
    "using": "Đang sử dụng",
    "used": "Đã sử dụng",
    "internal-partner": "Đối tác trong hệ thống",
    "external-partner": "Đối tác ngoài hệ thống",
    "parameter": "Tham số",
    "upload-doc-sample": "Tải lên mẫu",
    "edit-document": "Chỉnh sửa văn bản",
    "list": "Danh sách",
    "sure-to": "Bạn có chắc chắn muốn",
    "incorrect-format": "Không đúng định dạng",
    "over-capacity": "quá dung lượng",
    "staff": "Cán bộ nhân viên",
    "customer/partner": "Khách hàng/Đối tác",
    "process": "Quy trình",
    "personal": "Cá nhân",
    "switch-organization": "Chuyển tổ chức",
    "list-organization": "Danh sách tổ chức",
    "organization-title-change": "Thay đổi tổ chức",
    "organization-warning-change": "Bạn có chắc chắn muốn thay đổi tổ chức?",
    "organization-button-change": "Thay đổi",
    "capacity": "Dung lượng",
    "activities": "Hoạt động",
    "activities-history": "Lịch sử hoạt động",
    "detail": "Chi tiết",
    "loading": "Đang tải...",
    "field-is-required": "không được bỏ trống!",
    "restore": "Khôi phục",
    "invite": "Mời",

    //ConfigDigitalCertificate
    "name-remember-nonote": "Tên gợi nhớ",
    "name-remember": "Tên gợi nhớ (Người dùng tự đặt tên)",
    "view-ca-info": "Xem thông tin chữ ký số",
    "common.add.new_remote_sign": "Thêm mới CKS từ xa",
    "common.add.new_signatures_token": "Thêm mới CKS Token",
    "cant-view-info": "Bạn không thể xem thông tin này",
    "app_id": "Tài khoản",
    "secret_code": "Mật khẩu",
    "common.remote_sign":"Ký số từ xa",

    ///ConfigStorage
    "config-storage-title": "Thiết lập cấu hình lưu trữ file",
    "config-storage-save-storage": "Nơi lưu trữ hiện tại",
    "config-storage-personal": "Cá nhân",
    "config-storage-info-save-storage-personal": "Thông tin lưu trữ cá nhân",
    "config-storage-current-storage-type": "Loại lưu trữ",
    "config-storage-info-storage-personal": "Cấu hình storage cá nhân",
    "config-storage-google-client-id": "google_client_id",
    "config-storage-google-client-secret": "google_client_secret",
    "config-storage-google-folder-id": "google_folder_id",
    "config-storage-google-refresh-token": "google_refresh_token",
    "config-storage-dropbox-token": "Dropbox Token",

    //dashboard-navbar
    "data-collection": "Thống kê số liệu",
    "personal-doc": "Tài liệu cá nhân",
    "data-total": "Tổng số tài liệu",
    "data-pending": "Tài liệu đang xử lý",
    "data-reject": "Tài liệu bị từ chối",
    "doc-done": "Tài liệu hoàn thành",
    "doc-enterprise": "Tài liệu doanh nghiệp",
    "enterprise-servicepackage-info": "Thông tin gói dịch vụ doanh nghiệp",
    "upgrade-servicepackage": "Nâng cấp gói dịch vụ",
    "number-user": "Số lượng người dùng đang hoạt động/ Giới hạn người dùng theo gói dịch vụ",
    "number-procedure": "Số lượng quy trình đã tạo/ Số lượng quy trình có thể tạo",
    "number-doc-monthly": "Số lượng văn bản, hợp đồng đã sử dụng/ Số lượng văn bản, hợp đồng có thể thêm",
    "storage-capa": "Dung lượng lưu trữ đã sử dụng/ Tổng số dung lượng theo gói dịch vụ",
    "chartone-title": "Biểu đồ biến động tài liệu doanh nghiệp trong năm ",
    "doc-topbar": "Văn bản",
    "submit-topbar": "Trình ký văn bản",
    "approve-topbar": "Phê duyệt văn bản",
    "completed-topbar": "Văn bản đã phê duyệt",
    "refused-topbar": "Văn bản đã từ chối, quá hạn",
    "incoming-topbar": "Tài liệu đến",
    "outgoing-topbar": "Tài liệu đi",
    "archives-topbar": "Tài liệu",
    "personal-doc-topbar": "Tài liệu cá nhân",
    "type-doc-topbar": "Loại tài liệu",
    "utilities-topbar": "Tiện ích",
    "sample-doc": "Mẫu tài liệu",
    "sample-doc_create": "Mẫu tài liệu",
    "organization": "Tổ chức",
    "contract-manager-topbar": "Hợp đồng",
    "contract-topbar": "Trình ký hợp đồng",
    "template-contract-topbar": "Loại hợp đồng",
    "partner-topbar": "Quản lý đối tác",
    "type-processes": "Loại quy trình",
    "type-process": "Loại quy trình",
    "select-type-process": "Chọn loại quy trình",
    "type-procedure-list": "Danh sách loại quy trình",
    "processes-topbar": "Quản lý quy trình",
    "manage-organizationtree-topbar": "Quản lý sơ đồ tổ chức",
    "sample-email-topbar": "Quản lý mẫu email",
    "sample-sign-topbar": "Mẫu chữ ký",
    "manage-position-topbar": "Quản lý chức danh",
    "setting-cts-topbar": "Cấu hình chữ ký số",
    "system-topbar": "Hệ thống",
    "manage-role-topbar": "Quản lý vai trò",
    "manage-user-topbar": "Quản lý người dùng",
    "system-setting-topbar": "Cấu hình hệ thống",
    "filestorage-setting-topbar": "Cấu hình lưu trữ file",
    "mail-history-topbar": "Lịch sử gửi email",
    "user-manual-topbar": "Hướng dẫn sử dụng",
    "down-plugin-usb": "Tải plugin ký bằng USB token",
    "setup-wizard": "Khởi tạo nhanh",
    "software-ver": "Phiên bản phần mềm",
    "quick-sign": "Ký số nhanh",
    "charttwo-title": "Biểu đồ thể hiện đối tác doanh nghiệp",
    "hotline": "Tổng đài hỗ trợ",
    "version": "Phiên bản",
    "lang": "Ngôn ngữ",
    "contract-approve-topbar": "Phê duyệt hợp đồng",
    "this-contract-confirm": "phê duyệt hợp đồng này",
    "contract-complaints": "Khiếu nại hợp đồng",
    "code-complaints": "Mã khiếu nại",
    "complaint-title": "Tiêu đề khiếu nại",
    "complaint-content": "Nội dung khiếu nại",
    "complaint-time": "Thời gian khiếu nại",
    "contract-name": "Tên hợp đồng",
    "contract_complaints_create": "Thêm mới khiếu nại hợp đồng",
    "contract_complaints_view": "Xem chi tiết khiếu nại hợp đồng",
    "complaints-type": "Loại khiếu nại",
    "send-complaint": "Gửi khiếu nại",
    "other": "Khác",
    "contract-signing-time": "Thời gian giao kết hợp đồng",
    "processing": "Đang xử lý",
    "processed": "Đã xử lý",
    "tax_code_partner_complaint": "Mã số thuế đối tác khiếu nại",
    "name_partner_complaint": "Tên đối tác khiếu nại",
    "liquidation_agreement": "Biên bản thanh lý",

    //DialogCategoryPosition
    "add-position": "Thêm mới chức danh",
    "list-position": "Danh sách chức danh",
    "update-position": "Cập nhật chức danh",
    "enter-title-name": "Nhập tên chức danh",

    //EmailTemplate
    "email-sample": "mẫu email",
    "manage-emailsample": "Quản lý mẫu email",
    "email-sample-search-text": "Tìm theo tên, tiêu đề hoặc mã",
    "email-sample-code": "Mã mẫu email",
    "email-sample-name": "Tên mẫu email",
    "email-sample-title": "Tiêu đề email",
    "email-sample-content": "Nội dung email",
    "email-title-upgrade": "Tiêu đề email nâng cấp",
    "email-content-upgrade": "Nội dung email nâng cấp",
    "actived": "Hoạt động",
    "deactived": "Ngưng hoạt động",
    "not-active-yet": "Chưa hoạt động",
    "active": "Kích hoạt",
    "deactive": "Ngưng kích hoạt",
    "email-sample-active": "Kích hoạt mẫu email",
    "email-sample-active-message": "Bạn có chắc chắn muốn kích hoạt mẫu email",
    "email-sample-deactivate": "Ngưng kích hoạt mẫu email",
    "email-sample-deactivate-message": "Bạn có chắc chắn muốn ngưng kích hoạt mẫu email",
    "email-sample-update": "Cập nhật mẫu email",
    "email-sample-table-of-note": "Table of notes",
    "email-sample-param": "Parameter",
    "email-sample-title_vi": "Tiêu đề email Việt ngữ",
    "email-sample-title_en": "Tiêu đề email Anh ngữ",
    "email-sample-title_bilingual": "Tiêu đề email song ngữ",
    "email-sample-title_vi_upgrade": "Tiêu đề email Việt ngữ nâng cấp" ,
    "email-sample-title_en_upgrade": "Tiêu đề email Anh ngữ nâng cấp" ,
    "email-sample-title_bilingual_upgrade": "Tiêu đề email song ngữ nâng cấp" ,

    //InputUploadFile
    "select-device": "Chọn từ thiết bị",
    "select-personaldoc": "Chọn từ tài liệu cá nhân",
    "create-fromsample": "Tạo tài liệu từ mẫu",
    "create-many-fromsample": "Tạo nhiều tài liệu từ mẫu",

    //LogEmail
    "log-email-manage": "Quản lý lịch sử gửi mail",
    "log-email-status": "Trạng thái",
    "log-email-send-to": "Email người nhận",
    "log-email-title": "Tiêu đề email",
    "log-email-name": "Tên email",
    "log-email-code-or-title": "Tìm theo mã, tên hoặc tiêu đề",
    "log-email-resend": "Gửi lại",
    "log-email-view": "Xem chi tiết",
    "log-email-success": "Thành công",
    "log-email-failed": "Thất bại",
    "log-email-receiver": "Người nhận",
    "log-email-title-view": "Xem chi tiết lịch sử mẫu mail",
    "log-email-resend-mail": "Bạn có chắc chắn muốn gửi lại email",
    "log-email-send": "Gửi",

    //Login
    "password": "Mật khẩu",
    "login-fail": "Đăng nhập thất bại",
    "error-occur": "Có lỗi xảy ra!",

    //MenubarRight
    "notify": "Thông báo",
    "mark-all-read": "Đánh dấu tất cả là đã đọc",
    "remove-all-noti": "Gỡ tất cả thông báo",
    "mark-unread": "Đánh dấu là chưa đọc",
    "mark-read": "Đánh dấu là đã đọc",
    "remove-noti": "Gỡ thông báo",
    "no-noti": "Bạn không có thông báo nào!",
    "see-more": "Xem thêm",
    "password-change": "Thay đổi mật khẩu",
    "refresh": "Làm mới",

    //MenuFooter
    "office-adress": "VPGD: Tầng 4B, Tòa nhà T6-08 Tôn Quang Phiệt, Hà Nội.",
    "hotline-email": "Tổng đài: 1900.2038 - Email: support@cyberlotus.com",
    "product": "Sản phẩm",
    "cyberbill-bill": "Hóa đơn điện tử CyberBill",
    "cyberbill-tax": "Thuế điện tử CyberTax",
    "authen-device": "Thiết bị xác thực FIDO",
    "solution": "Giải pháp",
    "operation-administration": "Vận hành & Quản trị CNTT",
    "authen-ca": "Chứng thực chữ ký số",
    "sign-biometric-authen": "Ký số và xác thực sinh trắc học",
    "ca-mobile-authen": "Chữ ký số và định danh trên thiết bị di động",
    "service": "Dịch vụ",
    "system-integration": "Tích hợp hệ thống",
    "it-advisory": "Tư vấn CNTT",
    "it-administration": "Quản trị CNTT",
    "it-training": "Đào tạo CNTT",

    //OrganizationTree
    "parent-level": "Cấp bậc cha",
    "delete-record": "Xóa phòng/bộ phận",
    "delete-level-confirm": "Bạn có chắc chắn muốn xóa phòng/bộ phận này và các cấp con không?",
    "add-new-organization-tree": "Thêm mới phòng/bộ phận",
    "organization-tree-name": "Tên phòng/bộ phận",
    "organization-tree-list-belong-to": "Danh sách người dùng thuộc phòng/bộ phận",
    "organization-tree-view-detail": "Xem chi tiết phòng/bộ phận",
    "update-organization-tree": "Cập nhật phòng/bộ phận",
    "notify-check-again": "Vui lòng kiểm tra lại cấp quyền của quy trình, mẫu tài liệu khi thay đổi cấp bậc cha",

    "selected-list-title-c": "danh sách chức danh đã chọn",

    //Partner
    "partner-add-oz": "Thêm mới doanh nghiệp",
    "partner-add-per": "Thêm mới cá nhân",
    "partner-update-oz": "Cập nhật đối tác doanh nghiệp",
    "partner-update-per": "Cập nhật đối tác cá nhân",
    "partner-text-search": "Nhập tên hoặc mã định danh",
    "partner-identify-code": "Mã định danh",
    "partner-identify-type": "Loại định danh",
    "partner-name": "Tên đối tác",
    "partner-type": "Loại đối tác",
    "partner-personal-belong-to-oz": "Cá nhân thuộc tổ chức, doanh nghiệp",
    "partner-cn-business-name": "Tên tổ chức",
    "partner-cn-business-tax-code": "MST Tổ chức/doanh nghiệp",
    "partner-cn-business-position": "Tên phòng ban",
    "partner-dn-business-position": "Chức vụ",
    "partner-cn-business-email": "Email",
    "partner-cn-business-phone": "Số điện thoại",
    "partner-cn-business-address": "Địa chỉ tổ chức/doanh nghiệp",
    "partner-cn-name": "Tên cá nhân",
    "partner-cn-identify-type": "Loại định danh",
    "partner-cn-identify-code": "Mã định danh",
    "partner-cn-identify-code-id": "Số chứng minh nhân dân",
    "partner-cn-identify-code-passport": "Hộ chiếu",
    "partner-cn-identify-code-citizen": "Căn cước công dân",
    "partner-cn-identify-code-social": "Số bảo hiểm xã hội",
    "partner-cn-identify-issue-date": "Ngày cấp",
    "partner-cn-identify-place": "Nơi cấp",
    "partner-cn-tax-code": "Mã số thuế",
    "partner-cn-province": "Tỉnh thành",
    "partner-cn-district": "Quận huyện",
    "partner-cn-phone": "Số điện thoại",
    "partner-cn-address": "Địa chỉ thường trú",
    "partner-cn-if-any": "nếu có",
    "partner-cn-info-personal": "Thông tin cá nhân",
    "partner-message-delete": "Bạn có chắc chắn muốn xóa đối tác",
    "partner-message-delete-multi": "Bạn có chắc chắn muốn xóa danh sách đối tác đã chọn không?",
    "partner-header-delete": "Xóa đối tác",
    "partner-dn-identify-tax-code": "Mã số thuế",
    "partner-dn-identify-budget-code": "Mã ngân sách",
    "partner-dn-identify-establishment-decision-code": "Số quyết định",
    "partner-dn-identify-social-code": "Mã bảo hiểm xã hội",
    "partner-dn-identify-investment-code": "Số giấy phép đầu tư",
    "partner-dn-info-business": "Thông tin doanh nghiệp",
    "partner-dn-name": "Tên doanh nghiệp",
    "partner-dn-email": "Email",
    "partner-dn-phone": "Số điện thoại",
    "partner-dn-address": "Địa chỉ",
    "partner-dn-representative": "Người đại diện",
    "partner-dn-representative-name": "Tên người đại diện",
    "partner-dn-representative-identifier": "Số CMND/CCCD",
    "connect": "Kết nối",
    "refuseconnect": "Từ chối kết nối",
    "acceptconnect": "Duyệt kết nối",
    "disconnect": "Ngưng kết nối",
    "onconnecting": "Bật kết nối",
    "send-request": "Gửi yêu cầu",
    "connect-org": "Kết nối tổ chức",
    "refuse-connect-request": "Từ chối yêu cầu kết nối",
    "Partner.phone_partner": "Số điện thoại đối tác",
    "Partner.address_partner": "Địa chỉ đối tác",
    "Partner.tax_code_partner": "Mã số thuế đối tác",
    "Partner.code_oz": "Mã số doanh nghiệp",
    "Partner.email_oz": "Mã số doanh nghiệp",
    "Partner.phone_oz": "Số điện thoại doanh nghiệp",
    "Partner.address_oz": "Địa chỉ doanh nghiệp",
    "Partner.position_oz": "Chức vụ trong doanh nghiệp",
    "Partner.c_identifier_date": "Ngày cấp mã định danh",
    "Partner.c_identifier_place": "Nơi cấp mã định danh",
    "Partner.c_identifier_provider": "Tổ chức cấp mã định danh",
    "Partner.c_present_passpost": "CMT/CCCD người đại diện",
    "Partner.c_present_passpost_date": "Ngày cấp CMT/CCCD người đại diện",
    "Partner.c_present_passpost_place": "Nơi cấp CMT/CCCD người đại diện",
    "Partner.c_present_fullname": "Tên người đại diện",
    "Partner.c_present_position": "Chức vụ người đại diện",
    "Partner.c_present_phone": "Số điện thoại người đại diện",
    "Partner.c_present_email": "Email người đại diện",

    //PersonalDocument
    "personal-doc-upper": "Tài liệu cá nhân",
    "mydoc": "Tài liệu của tôi",
    "submitting": "Đang trình ký",
    "star": "Có gắn sao",
    "not-star": "Không gắn dấu sao",
    "shared": "Đã chia sẻ",
    "shared-with-me": "Được chia sẻ với tôi",
    "trash": "Thùng rác",
    "search-file-mydoc": "Tìm file trong tài liệu của tôi",
    "file-upload": "Tải lên file",
    "add-folder": "Tạo mới thư mục",
    "change-name-folder": "Đổi tên thư mục",
    "folder-name": "Tên thư mục",
    "type-folder-name": "Nhập tên thư mục",
    "back": "Trở lại",
    "existed-foldername": "Tên thư mục đã tồn tại",
    "sign_documents": "Ký tài liệu",

    //RoleManage
    "role-search-input-text": "Tên hiển thị hoặc tên vai trò",
    "role-table-name": "Tên vai trò",
    "role-table-display-name": "Tên hiển thị",
    "role-table-type-name": "Loại vai trò",
    "role-table-delete": "Xóa vai trò",
    "role-table-message-delete": "Bạn có chắc chắn muốn xóa vai trò",
    "role-table-message-delete-multi": "Bạn có chắc chắn muốn xóa danh sách vai trò đã chọn không?",
    "role-table-add": "Thêm mới vai trò",
    "role-table-list": "Danh sách vai trò",
    "role-table-update": "Cập nhật vai trò",
    "role-table-permission": "Quyền hạn",
    "role-table-view-detail": "Xem chi tiết vai trò",
    "role-table-view-detail-permission": "Xem chi tiết quyền hạn",
    "common.role.placeholder_name":"Nhâp tên vai trò. Ví dụ: Nhân viên kinh doanh",

    //SignTemplate
    "list-samplesign": "danh mục mẫu chữ ký",
    "code-samplesign": "Mã mẫu chữ ký",
    "name-samplesign": "Tên mẫu chữ ký",
    "type-samplesign": "Loại chữ ký",
    "code-name-signsample": "Mã, tên mẫu chữ ký",
    "image-upload-position": "Vị trí ảnh tải lên",

    //SignTemplateDialog
    "add-samplesign": "Thêm mới mẫu chữ ký",
    "update-samplesign": "Cập nhật mẫu chữ ký",
    "type-signature": "Loại chữ ký",
    "select-type-signature": "Chọn loại chữ ký",
    "show-ca": "Hiển thị chữ ký",
    "info-ca": "Thông tin chữ ký số",
    "select-show-info": "Nếu không chọn sẽ hiển thị thông tin cá nhân!",
    "type-show": "Loại hiển thị",
    "image-info": "Hình ảnh và thông tin",
    "image": "Hình ảnh",
    "info": "Thông tin",
    "org-signature": "Chữ ký tổ chức",
    "org-sample-sign": "Mẫu chữ ký số tổ chức",
    "label": "Nhãn",
    "time-sign-template": "Thời gian ký",
    "office": "Cơ quan",
    "e-sign": "Chữ ký điện tử (ảnh chữ ký)",
    "digital-signing": "Chữ ký số (cần có chữ ký số)",
    "draw-sign": "Vẽ chữ ký",
    "draw": "Vẽ",
    "select-img": "Chọn ảnh từ thiết bị",
    "name-organization": "Tên tổ chức",
    "signer": "Người ký",
    "name-signer": "Tên người ký",
    "name-office": "Tên cơ quan",
    "email-address": "Địa chỉ Email",
    "name-position": "Tên chức vụ",
    "unit": "Đơn vị",
    "name-unit": "Tên đơn vị",
    "date-format-slash": "Ngày/tháng/năm giờ:phút:giây",
    "date-format-minus": "Ngày-tháng-năm giờ:phút:giây",
    "font-size": "Cỡ chữ",
    "setting-font-size": "Cấu hình cỡ chữ",
    "text-info-template": "Kích thước hiển thị khi ô ký có kích thước lớn nhất",

    //UserManage
    "view-user-info": "Xem thông tin người dùng",
    "user-search-input-text": "Họ tên hoặc email",
    "user-table-name": "Họ tên",
    "user-table-role": "Vai trò",
    "user-table-email": "Email",
    "user-table-phone": "Điện thoại",
    "user-table-position": "Chức danh",
    "user-table-type-user": "Loại người dùng",
    "user-table-usage-capacity": "Dung lượng sử dụng",
    "user-table-lock": "Khóa",
    "user-table-un-lock": "Mở khóa",
    "user-table-reset-password": "Làm mới mật khẩu",
    "user-table-capacity-adjustment": "Điều chỉnh dung lượng",
    "user-not-exist": "Không tồn tại",
    "header-reset-password": "Đặt lại mật khẩu mặc định",
    "confirm-reset-password": "Bạn có chắc chắn muốn đặt lại mật khẩu người dùng",
    "error-file-import-type": "không đúng định dạng",
    "error-file-import-null": "Bạn chưa chọn file ",
    "delete-file-import": "Xóa file",
    "delete-user": "Xóa người dùng",
    "confirm-delete-user": "Bạn có chắc chắn muốn xóa người dùng",
    "confirm-delete-multi-user": "Bạn có chắc chắn muốn xóa danh sách người dùng đã chọn không?",
    "user-organization-tree-list": "Danh sách sơ đồ tổ chức",
    "user-header-add-user": "Thêm mới người dùng",
    "user-header-invite-user": "Mời người dùng",
    "user-header-update-user": "Cập nhật người dùng",
    "user-password": "Mật khẩu",
    "user-password-confirm": "Nhập lại mật khẩu",
    "user-password-suggest": "Mật khẩu phải có ít nhất 8 ký tự bao gồm ít nhất một ký tự thường, một ký tự viết hoa và một số",
    "user-title-update-status": "Cập nhật trạng thái",
    "user-message-lock-unlock": "Bạn có chắc chắn muốn",
    "user-message-lock-unlock-user": "người dùng",
    "user-adjustment-title": "Điều chỉnh",
    "user-adjustment-type": "Loại điều chỉnh",
    "user-capacity-used": "Dung lượng đã sử dụng",
    "user-capacity": "Dung lượng điều chỉnh",
    "user-adjustment-increment": "Tăng",
    "user-adjustment-decrease": "Giảm",
    "identifier-code": "CCCD/CMND",
    "not_active_yet": "Chưa hoạt động",
    "status_cyberid_removed": "CyberID đã gỡ khỏi doanh nghiệp",
    "user.status_cybersign_not_authorized": "Chưa được phần quyền sử dụng",

    //UserManual
    "user-manual-text-search": "Tên hướng dẫn sử dụng",
    "user-manual-title": "Tiêu đề",
    "user-manual-type": "Loại tài liệu",
    "user-manual-date": "Ngày phát hành",
    "user-manual-info": "Thông tin",
    "table-content": "Mục lục",
    "user-manual-view-detail": "Xem chi tiết hướng dẫn sử dụng",

    //Noti
    GET_FAIL: "Lấy danh sách thất bại",
    CREATE_SUCCESS: "Tạo mới thành công",
    CREATE_FAIL: "Tạo mới thất bại",
    UPDATE_SUCCESS: "Cập nhật thành công",
    UPDATE_FAIL: "Cập nhật thất bại",
    UPDATE_PASS_SUCCESS: "Đặt lại mật khẩu thành công",
    UPDATE_PASS_FAIL: "Đặt lại mật khẩu thất bại",
    DELETE_SUCCESS: "Xóa thành công",
    DELETE_FAIL: "Xóa thất bại",
    RESEND_SUCCESS: "Gửi lại thành công",
    RESEND_FAIL: "Gửi lại thất bại",
    SUCCESS: "Thành công",
    FAIL: "Thất bại",
    MESSAGE_ERROR: "Có lỗi xảy ra",
    MESSAGE_ERROR_SEARCH: "Không tìm thấy tài liệu",
    MESSAGE_ERROR_LOAD: "Có lỗi xảy ra, bạn cần tải lại trang web",
    MESSAGE_VALIDATE: "Kiểm tra lại dữ liệu nhập",
    SEND_EMAIL_SUCCESS: "Gửi email thành công",
    SEND_EMAIL_FAIL: "Gửi email thất bại",
    CHECK_SUCCESS: "Kiểm tra thành công",
    CHECK_FAIL: "Kiểm tra thất bại",
    NOTIFY_READ: "Đọc thông báo",
    NOTIFY_DELETE: "Gỡ thông báo",
    FILE_DOES_NOT_EXIST: "File không tồn tại",
    FILE_UPLOAD_SUCCESS: "Tải lên file thành công",
    FILE_TYPE_REQUIRE: "Loại file không được để trống",
    DATA_EXPORT_REQUIRE: "Dữ liệu xuất không được để trống",
    //Release notes
    "release_note": "Thông báo phát hành",

    "additional-information": "Thông tin bổ sung",
    "click-to-create-info-field": "Nhấp chuột tạo các trường thông tin dưới đây để thêm ô nhập văn bản hoặc ảnh",
    "click-to-create-info-sign-field": "Nhấp chuột tạo các trường thông tin dưới đây để thêm ô ký",
    "letters/numbers": "Chữ/số",
    "show": "Hiển thị",
    "procedure-has-doc-numbering": "(Quy trình này có đánh số văn bản)",
    "have-no-manager": "Bạn không có người quản lý!",
    "orga-signs-by-progress": "Tổ chức của tôi ký theo quy trình",

    "you-have": "Bạn có",
    "new-noti": "Thông báo mới",

    "send-in-order-party-involved": "Gửi theo thứ tự các bên tham gia",
    "send-contract-all-party": "Gửi hợp đồng đồng thời cho tất cả các bên",
    "send-my-orga-first": "Gửi cho tổ chức của tôi trước",
    "initialization": "Khởi tạo",

    "need-2-party": "Hợp đồng cần có hai bên tham gia",
    "need-1-party": "Hợp đồng cần có một bên tham gia",
    "duplicated-participants": "Bên tham gia bị trùng",
    "sign-position-info": "Thông tin vị trí ký",

    "doc-not-located": "Tài liệu chưa được đặt vị trí",
    "doc-is-located": "Tài liệu đã được đặt vị trí",
    "sign-doc": "Ký văn bản",
    "no-data": "Không có dữ liệu",
    "approve-this-doc": "phê duyệt văn bản này",
    "email-partner": "Email đối tác",
    "down-img": "Tải ảnh",
    "contract-status": "Trạng thái hợp đồng",
    "complete-time": "Thời gian hoàn thành",
    "user-type": "Loại người dùng",
    "email-code": "Mã email",
    "view-info-CKS": "Xem thông tin CKS",
    "type-procedure": "Loại quy trình",

    "PDF.fail-load-file": "Không tải được tệp PDF",
    "PDF.page-current": "Trang hiện tại",
    "PDF.first-page": "Trang đầu",
    "PDF.previous-page": "Trang trước",
    "PDF.next-page": "Trang tiếp",
    "PDF.last-page": "Trang cuối",

    "SIGN.check_BCT": "Xác nhận ký của bộ công thương",

    "message-not-exceed-1000": "Lời nhắn không được vượt quá 1000",

    "ChangeLog.Dialog.title": "Thông báo phát hành phiên bản phần mềm mới",
    "ChangeLog.Dialog.content": "CyberLotus thông báo đã nâng cấp phần mềm",
    "at": "lúc",

    "validate.required": "Trường này không được bỏ trống!",
    "validate.max_500": "Trường này không được vượt quá 500 ký tự!",

    "Notify.action": "Tùy chọn",

    "Sign.authority": "Ủy quyền",
    "Sign.authority_sign": "Ủy quyền ký",
    "Sign.authority_sign_confirm": "Hãy chắc chắn bạn muốn ủy quyền ký!",
    "Sign.signer": "Người ký",
    "Sign.signer_select": "Chọn người ký",
    "Sign.auth_to": "ủy quyền đến",
    "Sign.auth_time": "Thời gian ủy quyền",
    "Sign.auth_message": "Lời nhắn ủy quyền",
    "Sign.file_auth": "File ủy quyền",
    "Sign.select_file_auth": "Chọn file ủy quyền",
    "Sign.list_file_auth": "Danh sách file ủy quyền",

    "update-info-cyberid": "Cập nhật thông tin trên CyberID",
    "report": "Báo cáo",
    "synthesis_report": "Báo cáo tổng hợp",
    "total": "Tổng số",

    "common.are_you_sure": "Bạn có chắc chắn muốn",
    "common.check_sign_all_page": "Ký nháy cho tất cả các trang",
    "contract-liquidation": "Biên bản thanh lý",

    "add-batch-signer": "Thêm mới trình ký theo lô",
    "update-batch-signer": "Cập nhật trình ký theo lô",
    "contract_liquidation_management": "Quản lý biên bản thanh lý",
    "type_title_contract_liquidation": "Nhập tiêu đề biên bản thanh lý",
    "send_contract_liquidation": "Gửi biên bản thanh lý",
    "send-contract-liquidation-all-party": "Gửi biên bản thanh lý đồng thời cho tất cả các bên",
    "send-contract-liquidation": "Gửi biên bản thanh lý",
    "resend-contract-liquidation": "Gửi lại biên bản thanh lý",
    // ContractManagementDeleteLiquidation
    "delete-contract-liquidation-header": "Xóa biên bản thanh lý",
    "delete-contract-liquidation-before": "Bạn có chắc chắn muốn xóa biên bản thanh lý",
    "delete-list-contract-liquidation": "Bạn có chắc chắn muốn xóa biên bản thanh lý đã chọn không",

 	'common.file_extension':"Định dạng file",
    'common.list_file':"Danh sách file",

    "common.people":"Người",
    "common.active_status":"(có trạng thái hoạt động)",

    "common.server":"Server",
    "common.client":"Client",

    "common.resend":"Gửi lại",
    "common.add_lot":"Thêm mới theo lô",

    "common.process_create_lot":"Đang trong quá trình tạo lô",
    "common.process_sign":"Chờ xử lý ký",
    "common.administration":"Quản trị",
    "common.signatureManagement":"Quản lý chữ ký",
    "common.emailManagement":"Quản lý email",
    "common.serviceManagement":"Quản lý gói dịch vụ",

    "common.waitApprove":"Chờ phê duyệt",
    "common.Approved":"Đã phê duyệt",
    "common.refuseApprove":"Từ chối, quá hạn",
    "common.draft":"Lưu nháp",
    "common.completed":"Hoàn tất",

    "common.approve":"Phê duyệt",
    "common.refuse":"Từ chối",
    "common.info":"Thông tin",
    "common.requestSupport":"Yêu cầu hỗ trợ",
    "common.list":"Danh sách",
    "common.partnerOther":"Đối tác khác",

    "common.addPackage":"Mua thêm gói",
    "common.addServicePackage":"Mua thêm gói dịch vụ",

    "common.small":"Nhỏ",
    "common.average":"Trung bình",
    "common.large":"Lớn",
    "common.downCyberSignClient":"Tải xuống CyberSign Client",
    "common.fileIntegration":"File tích hợp",
    "common.fileIntegrationDetail":"Link file tích hợp của bên thứ ba",

    "common.numberOfDocumentContract":"số lượng văn bản, hợp đồng",
    "common.storageCapacity":"dung lượng lưu trữ",
    "common.numberOfUser":"số lượng người dùng",
    "common.numberOfProcess":"số lượng quy trình",


    "common.pleaseExtendService":"Vui lòng gia hạn để dịch vụ không bị gián đoạn",
    "common.almostOver":"sắp hết",

    "common.digitalSignature":"Chữ ký số",
    "common.aboutExpire":"sắp hết hạn",
    "common.willExpireOn":"sẽ hết hạn vào",
    "common.warning":"Cảnh báo",

}
